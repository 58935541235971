var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',[_c('CToaster',{attrs:{"autohide":3000}},[_vm._l((_vm.infoList),function(info){return [_c('CToast',{key:info.message,attrs:{"show":true,"header":info.header,"color":info.color}},[_vm._v(" "+_vm._s(info.message)+". ")])]})],2),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v(" "+_vm._s(_vm.obj.name)+" ")]),_vm._v(" Permission ")]),_c('CCardBody',[_c('CForm',[_c('CDataTable',{attrs:{"items":_vm.computedItems,"fields":_vm.fields,"hover":"","striped":""},scopedSlots:_vm._u([{key:"cell_canView",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CSwitch',{attrs:{"color":"primary","checked":item.canView,"label-on":"YES","label-off":"NO"},on:{"update:checked":function($event){return _vm.$set(item, "canView", $event)}}})],1)]}},{key:"cell_canAdd",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CSwitch',{attrs:{"color":"primary","checked":item.canAdd,"label-on":"YES","label-off":"NO"},on:{"update:checked":function($event){return _vm.$set(item, "canAdd", $event)}}})],1)]}},{key:"cell_canUpdate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CSwitch',{staticClass:"mr-1",attrs:{"color":"primary","checked":item.canUpdate,"label-on":"YES","label-off":"NO"},on:{"update:checked":function($event){return _vm.$set(item, "canUpdate", $event)}}})],1)]}},{key:"cell_canDelete",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CSwitch',{staticClass:"mr-1",attrs:{"color":"primary","checked":item.canUDelete,"label-on":"YES","label-off":"NO"},on:{"update:checked":function($event){return _vm.$set(item, "canUDelete", $event)}}})],1)]}}])})],1)],1),_c('CCardFooter',[_c('CButton',{attrs:{"type":"submit","size":"sm","color":"primary"},on:{"click":_vm.submit}},[_c('CIcon',{attrs:{"name":"cil-check-circle"}}),_vm._v(" Submit")],1),_c('CButton',{staticClass:"ml-1",attrs:{"color":"secondary"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }